import React from "react"
import Footer from "../../../../components/Footer/Footer";
import SupportLeftNav from "../../../../components/TwoColumnLeftNav/SupportLeftNav";
import SoftwareDownloadButton from "../../../../components/SoftwareDownload/SoftwareDownloadButton";

const Sk_musen = () => {
    // useTranslations is aware of the global context (and therefore also "locale")
    // so it'll automatically give back the right translations

    return (
        <>
            <article className="company-info two-column-left-nav">
                <SupportLeftNav/>
                <section className="main-content">
                    <div className="content-container">
                        <h2 className={'large bold'}>無線温度・温湿度ロガー SK-L700Rシリーズ用　ソフトウェアダウンロード</h2>

                        <div className="software-content">
                            <div className="terms-of-service-container">
                                <div className="content-pre">
                                    <p className="equal-line">＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</p>
                                    <p>このドキュメントは本製品に関する重要な情報について書かれています。<br/>
                                        ご使用の前に、必ずご一読くださるようお願いいたします。</p>
                                    <p className="equal-line">＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</p>
                                </div>
                                <div className="content-title">
                                    <p>＜利用規約＞</p>
                                </div>
                                <div className="content-disclaimer">
                                    <div className="content-disclaimer-title">
                                        <p> ■免責事項</p>
                                    </div>
                                    <div className="content-disclaimer-content quote">
                                        <p>
                                            株式会社佐藤計量器製作所は本製品に関して動作確認はおこなっておりますが、全ての状況下において動作を保証しているわけではありません。<br/>
                                            本製品により生じた損害は、直接的および間接的損害を問わず、弊社に一切の賠償責任はないものとします。<br/>
                                            操作上のミス、注意を無視した操作、ご使用になられているＰＣに起因する不具合、お客様の使用環境により発生した無線通信の不通等により生じたデータ欠損および消失に対して、弊社は責任を負いかねますのでご了承ください。<br/><br/>
                                            ※こちらで提供いたしております各種ソフトウェアは、日本国内で販売されている各製品と日本語版OSの組み合わせのみ、動作確認をおこなっております。<br/>
                                            海外版OSとの組み合わせにて発生したトラブルに関しましては、一切保証およびサポートいたしかねますのでご了承ください。
                                        </p>
                                    </div>
                                </div>
                                <div className="content-copyright">
                                    <div className="content-copyright-title">
                                        <p> ■著作権</p>
                                    </div>
                                    <div className="content-copyright-content">
                                        <ol>
                                            <li>
                                                「SKSATO無線ロガー for Windows」のプログラムおよび関連ドキュメントに関する著作権は株式会社佐藤計量器製作所に帰属します。
                                            </li><br/>
                                            <li>
                                                「SKSATO無線ロガー for Windows」は以下条件をもとに、無償でご利用いただけます。
                                                <ul>
                                                    <li>
                                                        <p>逆コンパイル・ファイル解析、改変等を禁じます。</p>
                                                    </li>
                                                    <li>
                                                        <p>本プログラムの使用による損害は、弊社は一切の責任を負いません。</p>
                                                    </li>
                                                    <li>
                                                        <p>再配布は自由としますが、企業内・企業間、その他関係においても営利目的にしないこと。<br/>
                                                            再配布したプログラムの使用による損害に対しても弊社は一切の責任を負いません。</p>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ol>
                                        <p className="quote">本利用規約に同意いただけた方にのみ、以下ソフトウェアをご利用いただけます。</p>
                                    </div>
                                </div>
                                <div className="content-feature">
                                    <div className="content-feature-title">
                                        <p> ■ソフトウェアの特徴</p>
                                    </div>
                                    <div className="content-feature-content">
                                        <ol>
                                            <li>
                                                <p>「SKSATO無線ロガー for Windows」は、無線ロガーSK-L700Rの無線通信設定及び子機（中継器含む）の各設定がおこなえます。</p>
                                            </li>
                                            <li>
                                                <p>無線ロガーで測定、記憶したデータは「SKSATO無線ロガー for Windows」を用いてパソコンに回収し、データの解析（グラフ表示、印刷等）がおこなえます。また、データをCSV形式のファイルとして保存した場合は、市販のCSV形式対応の表計算ソフトでデータ解析が可能です。</p>
                                            </li>
                                            <li>
                                                <p>リアルタイム監視機能では、計測値を定期的に取得してソフトウェアで確認することができます。リアルタイム監視で警報情報を取得した場合にメールで知らせることができます。</p>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="way-to-install">
                                <div className="install-title">
                                    <p className="bold">【インストール方法】</p>
                                </div>
                                <div className="install-content">
                                    <p>「<a href="https://d3hdfc67hsdxgb.cloudfront.net/corporate/pdfs/無線ロガーソフトウェアインストールマニュアル.pdf" target={"_blank"} rel="noreferrer">無線ロガーソフトウェアインストールマニュアル.pdf</a>」に従ってインストールをおこなってください。</p>
                                    <p className="important-text">(インストール前に必ずお読みください。)</p>
                                    <p>ダウンロードしたファイルを実行するとソフトウェアのインストールが始まりますので、画面の指示に従ってインストールを進めてください。</p>                                                                     
                                    <p>
                                        ご注意：
                                        <br/>
                                        旧バージョンがインストールされている場合、旧バージョンをアンインストールしてから、新しいバージョンをインストールしてください。
                                        <br/>
                                        なお、現在ご使用中のソフトのウェアバージョンが「Ver1.4」より古い場合は、旧バージョンが残っている状態で、新しいバージョンをインストールしてください。
                                    </p>
                                </div>
                            </div>
                            <div className="download">
                                <div className="download-title">
                                    <p className="bold">【ダウンロード】</p>
                                </div>
                                <div className="download-pre">
                                    <p>※ご使用されるパソコンのOSバージョンおよび、bit数をご確認の上、対応するソフトウェアをダウンロードしてください。</p>
                                </div>
                                <div className="download-content">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <th>
                                                <p className="download-table-title">ソフトウェア名</p>
                                            </th>
                                            <td>
                                                <p>SKSATO無線ロガー for Windows Ver.1.51J　　　　　（2021年5月25日掲載）</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <p className="download-table-title">対象機器</p>
                                            </th>
                                            <td>
                                                <p>SK-L700R</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <p className="download-table-title">対象OS</p>
                                            </th>
                                            <td>
                                                <p>
                                                    Windows11<br/>
                                                    Windows10 （64bit版/32bit版）<br/>
                                                    Windows8.1（64bit版/32bit版）<br/>
                                                    Windows8　（64bit版/32bit版）<br/>
                                                    Windows7　（64bit版/32bit版、SP1以上）
                                                </p>
                                                <p>
                                                    ※Windows7、8、8.1について<br/>
                                                    　上記OSはMicrosoft社のOSサポートが終了しています。<br/>
                                                    　ソフトウェアは引き続きご利用いただけますが、上記OS上での動作や不具合などについての<br/>
                                                    　お問い合わせには回答いたしかねますのでご了承ください。
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <p className="download-table-title">ダウンロード</p>
                                            </th>
                                            <td>
                                                <SoftwareDownloadButton
                                                    yourKey64bit="corporate/software/SK_Musen151_64.zip"
                                                    tmpKey64bit="software/SK_Musen151_64.zip"
                                                    yourKey32bit="corporate/software/SK_Musen151_32.zip"
                                                    tmpKey32bit="software/SK_Musen151_32.zip"
                                                />
                                                <p>※ご使用のウェブブラウザーによっては、ダウンロードが正しくおこなわれない場合があります。<br/>
                                                　その場合は下記をご参照の上、ダウンロードをおこなってください。<br/>
                                                　　Microsoft Edge：<a href="https://d3hdfc67hsdxgb.cloudfront.net/corporate/pdfs/Edgeご使用時の補足.pdf" target={"_blank"} rel="noreferrer">「Edgeご使用時の補足.pdf」</a><br/>
                                                　　Google Chrome ：<a href="https://d3hdfc67hsdxgb.cloudfront.net/corporate/pdfs/Chromeご使用時の補足.pdf" target={"_blank"} rel="noreferrer">「Chromeご使用時の補足.pdf」</a>
                                                </p>                        
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <p className="download-table-title">バージョンアップ内容</p>
                                            </th>
                                            <td>
                                                <p>バージョン1.50Jにてパソコンにソフトウェアを新規でインストール後、ソフトウェアを起動するとすでに機器が登録されている画面が表示され、初回操作時、設定ファイルの新規作成をおこなわなければならない問題を修正しました。</p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="version-up-history">
                                <div className="version-up-title">
                                    <p>【バージョンアップ履歴】</p>
                                </div>
                                <div className="version-up-contents">
                                    <ol>
　　                                    <li>
                                            <p>Ver.1.50J（2021年3月5日掲載）</p>
                                            <p>メール送信設定機能において、STARTTLS方式への対応をおこないました。</p>
                                            <p>※ポート番号は587を設定してください。</p>
                                        </li>
　　                                    <li>
                                            <p>Ver.1.41J（2019年7月23日掲載）</p>
                                            <p>以下の不具合を修正しました。</p>
                                            <p>　バージョン1.31Jで発生したダウンロード時に起きる不具合</p>
                                            <p>　データ一覧での平均値計算の誤り</p>
                                            <p>　積算解析にて、抽出データ計算の誤り</p>
                                            <p>　機器登録にて、子機名称が中継器の名称と一部でも重複するとルート構築ができなくなる現象を、</p>
                                            <p>　完全一致となる場合のみ構築不可となるように修正</p>
                                        </li>
                                        <li>
                                            <p>Ver.1.31J（2018年8月22日掲載）</p>
                                            <p>操作性の向上を図りました。</p>
                                            <p>以下の不具合を修正しました。</p>
                                            <p>　解析処理時、CH2の平均データが印刷されない。</p>
                                            <p>　警報メールが設定条件通りに送信されない。</p>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </article>
            <Footer/>
        </>
    )
}

export default Sk_musen
